// extracted by mini-css-extract-plugin
export const useCasesContainer = "index-module__useCasesContainer--1uQqk";
export const useCaseTeaser = "index-module__useCaseTeaser--WgO2c";
export const withLink = "with-link";
export const useCaseLink = "use-case-link";
export const useCaseTitle = "use-case-title";
export const useCaseDescription = "use-case-description";
export const useCaseHero = "index-module__useCaseHero--GzEuy";
export const useCaseHeroTitle = "use-case-hero-title";
export const useCaseHeroDescription = "use-case-hero-description";
export const actionContainer = "action-container";
export const cta = "cta";
export const secondary = "secondary";
export const useCaseCategory = "index-module__useCaseCategory--U7Bwk";
export const useCaseCategoryTitle = "use-case-category-title";