import React from 'react'
// import propTypes from 'prop-types'
import { actionMetaContainer } from './styles.module.scss'

const IconCheckmark = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }}>
    <path
      d="M5.06066 11.8389C4.47487 12.4247 4.47487 13.3744 5.06066 13.9602L9.65685 18.5564L18.4957 9.71756C19.0815 9.13177 19.0815 8.18202 18.4957 7.59624C17.9099 7.01045 16.9602 7.01045 16.3744 7.59624L9.65685 14.3137L7.18198 11.8389C6.59619 11.2531 5.64645 11.2531 5.06066 11.8389Z"
      fill="var(--neutral-200)"
    />
  </svg>
)

const ActionMetaInfo = props => {
  return (
    <div className={actionMetaContainer} {...props}>
      <span className="action-meta-info">
        <IconCheckmark /> No credit card required
      </span>
      <span className="action-meta-info">
        <IconCheckmark /> Unlimited time on Free plan
      </span>
    </div>
  )
}

// Container.propTypes = {
//   size: propTypes.string,
//   className: propTypes.string,
// }

export default ActionMetaInfo
