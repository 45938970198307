import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { contentBlockLargeContainer } from './index.module.scss'

const ContentBlockLargeWithGallery = props => {
  const [activeElement, setActiveElement] = useState(props.content[0].linkTitle)

  return (
    <div className={`${contentBlockLargeContainer} ${props.media ? '' : 'full-width'}`}>
      <div className={`inner-wrapper ${props.reverse ? 'reverse' : ''} ${props.alignVertically ? 'vertically-aligned' : ''}`}>
        <div className="content">
          {props.title ? (
            <span className="block-title" style={{ color: props.highlightColor || 'var(--primary)' }}>
              {props.title}
            </span>
          ) : null}
          <div className="inline-nav">
            {props.content.map(item => (
              <button
                className={`nav-item ${item.linkTitle === activeElement ? 'active' : ''}`}
                key={item.linkTitle}
                onClick={() => setActiveElement(item.linkTitle)}
                style={{ background: item.linkTitle === activeElement ? props.highlightColor || 'var(--primary)' : '' }}
              >
                {item.linkTitle || 'N/A'}
              </button>
            ))}
          </div>

          {props.content
            .filter(content => content.linkTitle === activeElement)
            .map(item => (
              <React.Fragment key={item.linkTitle}>
                <h1 className="headline">
                  {item.title}
                  {item.comingSoon ? <span className="coming-soon" /> : null}
                </h1>
                <span className="description">{item.description}</span>
                {props.actionLink &&
                  (props.actionLinkIsExternal ? (
                    <a className="linkBtn primary" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
                      {props.actionLinkText}
                    </a>
                  ) : (
                    <Link className="linkBtn primary" to={props.actionLink}>
                      {props.actionLinkText}
                    </Link>
                  ))}
              </React.Fragment>
            ))}
        </div>

        {props.content
          .filter(content => content.linkTitle === activeElement)
          .map(item => (
            <div key={item.linkTitle} className="media-container">
              {item.media}
            </div>
          ))}
      </div>
    </div>
  )
}

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentBlockLargeWithGallery
