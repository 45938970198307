import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import { useCasesContainer, useCaseTeaser, useCaseHero, useCaseCategory } from './index.module.scss'
import ActionMetaInfo from '../content-action-meta-info'

export const UseCasesContainer = props => <div className={useCasesContainer}>{props.children}</div>

export const UseCaseTeaser = props =>
  props.to ? (
    <Link className={`${useCaseTeaser} with-link`} to={props.to}>
      <span className="use-case-title">{props.title || 'N/A'}</span>
      <span className="use-case-description">{props.description || 'N/A'}</span>
      <span className="use-case-link">Learn more</span>
    </Link>
  ) : (
    <div className={useCaseTeaser}>
      <span className="use-case-title">{props.title || 'N/A'}</span>
      <span className="use-case-description">{props.description || 'N/A'}</span>
    </div>
  )

export const UseCaseHero = props => (
  <div className={useCaseHero} to={props.to}>
    <div className="use-case-hero-content">
      <h1 className="use-case-hero-title">{props.title || 'N/A'}</h1>
      <span className="use-case-hero-description">{props.description || 'N/A'}</span>
    </div>
    {props.actionLink || props.demoButton ? (
      <div className="action-container">
        {props.actionLink &&
          (props.actionLinkIsExternal ? (
            <a className="cta" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
              {props.actionLinkText}
            </a>
          ) : (
            <Link className="cta" to={props.actionLink}>
              {props.actionLinkText}
            </Link>
          ))}

        {props.demoButton && (
          <Link className="secondary" to="/demo">
            Book a Demo
          </Link>
        )}

        <ActionMetaInfo style={{ marginTop: '16px' }} />
      </div>
    ) : null}
  </div>
)

export const UseCaseCategory = props => (
  <div className={useCaseCategory}>
    <span className="use-case-category-title">{props.title}</span>
  </div>
)

export const UseCaseBlock = props => {
  const useCases = [
    {
      title: 'Digital Employee Experience',
      description: 'Provide your people with a great Digital Headquarters for all their daily business, no matter where they are.',
      to: '/lp/digital-employee-experience-dex/',
    },
    {
      title: 'Optimizing Remote Work',
      description: 'Provide a Digital Hub for all your apps and empower your employees to be more productive and engaged.',
      to: '/lp/optimizing-remote-work/',
    },
    {
      title: 'Excellent Employee Onboarding',
      description: 'Provide a positive and streamlined experience for your new hires and welcome your new colleagues with a fully equipped Digital Workplace.',
      to: '/lp/employee-onboarding/',
    },

    {
      title: 'Improve Employee Productivity',
      description: 'Empower your people to optimize their productivity and offer a Digital Hub where they can find everything to improve their workflows.',
      to: '/lp/employee-productivity/',
    },

    {
      title: 'Enhance New Work Environment',
      description: 'Connect all your apps and employees into a Digital Hub and motivate your people with first class new work experience.',
      to: '/lp/new-work-environment/',
    },
    {
      title: 'Intranet from your existing tools',
      description: 'Connect all your existing collaboration and content apps into a Best-of-Breed Intranet and offer your people a unified digital headquarters.',
      to: '/lp/intranet-from-existing-apps/',
    },
    {
      title: 'SaaS & Link Management',
      description: 'Roll-out your company apps or share content and information for teams, departments, or individual users into one central digital workplace.',
      to: '/lp/saas-app-cloud-link-management/',
    },
    {
      title: 'Digital Transformation',
      description: `Increase your staff's support for digital transformation with a single place where they can find all apps or information they need in their daily workflows.`,
      to: '/lp/digital-transformation/',
    },
  ]

  return (
    <UseCasesContainer>
      {useCases
        .filter(useCase => `${useCase.to}` !== props.location)
        .map((useCase, index) => (
          <React.Fragment key={index}>
            <UseCaseTeaser key={index} title={useCase.title} description={useCase.description} to={useCase.to} />
          </React.Fragment>
        ))}
    </UseCasesContainer>
  )
}

// Subheadline.propTypes = {
//   headline: PropTypes.string.isRequired,
// }

// Subheadline.defaultProps = {
//   headline: '+++ Missing subheadline +++',
// }
